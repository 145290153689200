<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-edit"
        @click="dialogVisible = true"
        >添加</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="60">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="手机号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="手机号" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.contact" :key="index">
            {{ item }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="mini" @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="70px"
      >
        <el-form-item label="电话号码" prop="name" style="width: 80%">
          <el-select
            v-model="form.phone"
            filterable
            remote
            reserve-keyword
            placeholder="请输入电话号码"
            :remote-method="getPhoneList"
            :loading="loading"
          >
            <el-option
              v-for="item in phoneList"
              :key="item.id"
              :label="item.phone"
              :value="item.phone"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="支付方式修改" :visible.sync="dialogVisible1" width="30%">
      <el-radio-group v-model="pay_method.pay_method">
        <el-radio label="weixin">微信支付</el-radio>
        <el-radio label="ccb">建行支付</el-radio>
        <el-radio label="sp">服务商支付</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleStore">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import { mapGetters } from "vuex";

export default {
  components: {
    AMapPosition,
  },
  data() {
    return {
      list: null,
      total: null,
      btnLoading: false,
      listLoading: true,
      loading: false,
      phoneList: [],
      listQuery: {
        page: 1,
        limit: 10,
        ledger_role_id: "",
      },
      dialogVisible: false,
      dialogVisible1: false,
      form: {
        id: null,
        ledger_role_id: "",
        phone: "",
      },
      pay_method: {
        id: "",
        pay_method: "",
      },
      inputVisible: false,
      inputValue: "",
    };
  },
  created() {
    this.listQuery.ledger_role_id = this.$route.query.ledger_role_id;
    this.form.ledger_role_id = this.$route.query.ledger_role_id;
    this.getList();
  },
  computed: {
    ...mapGetters(["school_id"]),
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.form.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: null,
          ledger_role_id: "",
          phone: "",
        };
        this.form.ledger_role_id = this.$route.query.ledger_role_id;
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/school/ledgerRolePhoneList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getPhoneList(query) {
      this.loading = true;
      if (query !== "") {
        request({
          url: "/api/backend/school/phoneList",
          method: "get",
          params: {
            phone: query,
            ledger_role_id: this.listQuery.ledger_role_id,
          },
        }).then((response) => {
          this.phoneList = response.data;
          this.loading = false;
        });
      } else {
        this.phoneList = [];
      }
    },
    choosePosition(res) {
      console.log(res);
      this.form.lng = res.position.lng;
      this.form.lat = res.position.lat;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleClose(tag) {
      this.form.contact.splice(this.form.contact.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.contact.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleEdit(item) {
      this.form = item;
      this.dialogVisible = true;
    },
    getLedgerRoleDetail(id) {
      request({
        url: "/api/backend/school/ledgerRoleDetail",
        method: "get",
        params: { id: id },
      }).then((response) => {
        this.form = response.data;
        this.form.income_type = this.form.income_type + "";
        console.log(this.form);
      });
    },
    handleDel(item) {
      this.$confirm("确定要删除该绑定关系吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/phoneDel",
          method: "get",
          params: {
            id: item.id,
          },
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
    handleUse(item) {
      this.listLoading = true;
      request({
        url: "/api/backend/school/use",
        method: "get",
        params: {
          id: item.id,
        },
      }).then(() => {
        this.getList();
        this.$store.dispatch("GetSchools");
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
    saveData() {
      if (!this.form.phone) {
        this.$message({
          type: "warning",
          message: "请输入手机号码",
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/school/phoneStore",
        method: "post",
        data: this.form,
      })
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    show(item) {
      this.pay_method.id = item.id;
      this.pay_method.pay_method = item.pay_method;
      this.dialogVisible1 = true;
    },
    handleStore() {
      this.$confirm("确定修改学校的支付方式吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/payMethodStore",
          method: "get",
          params: this.pay_method,
        }).then(() => {
          this.dialogVisible1 = false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
.input {
  display: flex;
}
</style>
